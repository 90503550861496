<script setup lang="ts">
const visible = ref<'cancel' | 'add' | 'manage' | null>(null);

const DIALOGS = {
  cancel: 'Twoje członkostwo',
  add: 'Dodaj metodę płatności',
  manage: 'Zarządzaj metodami płatności',
};

const { data: cards } = useCards();
const { currentSubscription } = useSubscriptions();
</script>

<template>
  <PrimeButton text @click="visible = 'add'">
    <i class="pi pi-plus" />
    <span class="hidden lg:block"> Dodaj kartę </span>
  </PrimeButton>
  <PrimeButton
    v-if="cards && cards.length > 0"
    text
    @click="visible = 'manage'"
  >
    <i class="pi pi-cog" />
    <span class="hidden lg:block"> Zarządzaj </span>
  </PrimeButton>
  <PrimeButton
    v-if="currentSubscription && !currentSubscription.ends_at"
    severity="danger"
    text
    @click="visible = 'cancel'"
  >
    <i class="pi pi-ban" />
    <span class="hidden lg:block"> Anuluj subskrypcję </span>
  </PrimeButton>

  <PrimeDialog
    :visible="!!visible"
    modal
    :header="typeof visible === 'string' ? DIALOGS[visible] : ''"
    :style="{ width: '517px', 'max-width': '90%' }"
    closeIcon="pi pi-times-circle"
    :closeButtonProps="{
      text: true,
      severity: 'secondary',
      style: 'box-shadow: none;',
    }"
    @update:visible="visible = null"
  >
    <LazyCancelMembershipForm
      v-if="visible === 'cancel'"
      :closeModal="() => (visible = null)"
    />
    <LazyAddPaymentCard
      v-if="visible === 'add'"
      :closeModal="() => (visible = null)"
    />
    <LazyManagePaymentSettings
      v-if="visible === 'manage'"
      :closeModal="() => (visible = null)"
    />
  </PrimeDialog>
</template>

<script setup lang="ts">
const { defaultCard } = useCards();
const { data: user } = useUser();
const { membershipSubscriptionPrice } = useProducts();

const isCardExpiringMessage = computed(() => {
  const card = defaultCard.value;
  const userZmid = user.value?.zmidAssoc;

  if (!card || !userZmid) return false;

  const { end_date, is_active } = userZmid;

  if (!end_date || !is_active) return false;

  const { exp_year, exp_month } = card;

  return isDateBefore(`${exp_year}-${exp_month}-01`, end_date);
});
</script>

<template>
  <PrimeCard e>
    <template #title>
      <h2
        class="mb-6 flex items-center gap-7 text-lg font-semibold text-bluegray-900 lg:gap-9"
      >
        <span class="mr-auto"> Płatność </span>

        <SubscriptionActionButtons />
      </h2>
    </template>

    <template #content>
      <PrimeMessage
        v-if="isCardExpiringMessage"
        class="[&__div]:items-start"
        severity="warn"
        icon="pi pi-exclamation-triangle !text-3xl !mr-3"
      >
        <header class="font-bold">Zaktualizuj metodę płatności</header>
        <p class="mt-1 font-normal">
          Data wygaśnięcia ważności karty nastąpi przed kolejnym rozliczeniem.
          Aby zachować ciąg płatności, dodaj nową kartę.
        </p>
      </PrimeMessage>

      <div
        class="grid grid-cols-1 gap-6 text-sm text-bluegray-900 lg:grid-cols-3"
      >
        <div class="space-y-1.5">
          <span class="text-bluegray-500">Metoda płatności</span>

          <CardPreview v-if="defaultCard" :card="defaultCard" />
          <div v-else>-</div>
        </div>

        <div class="space-y-1.5">
          <span class="text-bluegray-500">Następna płatność</span>
          <div v-if="user?.zmidAssoc?.end_date">
            {{ formatDateToPolishLong(user.zmidAssoc.end_date) }}
          </div>
          <div v-else>-</div>
        </div>

        <div class="space-y-1.5">
          <span class="text-bluegray-500">Cena</span>
          <div v-if="membershipSubscriptionPrice">
            {{ membershipSubscriptionPrice.unit_amount_with_tax_formatted }}
            {{ membershipSubscriptionPrice.currency.toUpperCase() }}
            /
            {{
              membershipSubscriptionPrice.recurring_interval === 'year'
                ? 'rok'
                : membershipSubscriptionPrice.recurring_interval
            }}
          </div>
        </div>
      </div>
    </template>
  </PrimeCard>
</template>

<script setup lang="ts">
const { data: paymentsHistory, isLoading: isPaymentsLoading } =
  usePaymentsHistory();
</script>

<template>
  <PrimeCard>
    <template #title>
      <h2 class="mb-6 text-lg font-semibold text-bluegray-900">
        Historia płatności
      </h2>
    </template>

    <template #content>
      <div v-if="isPaymentsLoading" class="text-center">
        <PrimeProgressSpinner class="h-8 w-8" />
      </div>

      <div
        v-else-if="!paymentsHistory?.length"
        v-auto-animate
        class="text-sm text-bluegray-800"
      >
        Brak historii płatności.
      </div>

      <div
        :key="payment.id"
        v-for="payment in paymentsHistory"
        class="divide-y-[1px]"
      >
        <div class="grid grid-cols-4 py-3 text-xs text-bluegray-900 md:text-sm">
          <span v-if="payment.paymentable.paid_at">
            {{ formatDateToPolishLong(payment.paymentable.paid_at) }}
          </span>
          <span v-else-if="payment.paymentable.current_period_start_at">
            {{
              formatDateToPolishLong(
                payment.paymentable.current_period_start_at,
              )
            }}
          </span>
          <span class="justify-self-center">
            {{ payment.amount_paid_with_tax_formatted }} PLN
          </span>

          <span
            class="justify-self-center font-semibold text-message-success-borderColor"
          >
            Opłacona
          </span>

          <i class="pi pi-credit-card justify-self-center" />
        </div>
      </div>
    </template>
  </PrimeCard>
</template>

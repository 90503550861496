import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { subscriptionsService } from '@/utils/api/subscriptionsService';
import { membershipService } from '@/utils/api/membershipService';
import type { ManageSubscriptionProps, MembershipForm } from '~/utils/types';

export function useResumeSubscription(form: MembershipForm) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { data: user } = useUser();

  const { mutate, isPending } = useMutation({
    mutationFn: (payload: ManageSubscriptionProps) =>
      subscriptionsService.resumeSubscription(payload),
    onSuccess: (data) => {
      toast.add({
        severity: 'success',
        detail: 'Subskrypcja została wznowiona',
        life: 3000,
      });

      queryClient.invalidateQueries({
        queryKey: ['subscriptions'],
      });
    },
    onError: () => {
      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas wznawiania subskrypcji',
        life: 3000,
      });
    },
  });

  function handleResumeMembership({ onSuccess }: { onSuccess?: () => void }) {
    if (!user.value?.zmidAssoc?.subscribction_id) {
      throw new Error('No subscription price id found');
    }

    mutate(
      { subscription_id: user.value.zmidAssoc.subscribction_id },
      { onSuccess },
    );
  }

  return {
    handleResumeMembership,
    isPending,
  };
}

import { fetcher } from '../fetcher';
import * as Sentry from '@sentry/vue';

const SUBSCRIPTIONS_ENDPOINT = '/subscriptions';

const { STRIPE_URL } = useRuntimeConfig().public;

if (!STRIPE_URL) {
  Sentry.captureException(
    new ResponseError('STRIPE_URL environment variable is not set', 111),
  );

  showError({
    message:
      'Coś poszło nie tak przy pobieraniu danych. Spróbuj ponownie później.',
  });
}

export const subscriptionsService = {
  get: async () => {
    return await fetcher(`${SUBSCRIPTIONS_ENDPOINT}`, {
      method: 'GET',
      customApiUrl: `${STRIPE_URL}`,
      schema: subscriptionsSchema,
    });
  },
  createSubscription: async (payload: CreateSubscriptionProps) => {
    return await fetcher(`${SUBSCRIPTIONS_ENDPOINT}`, {
      method: 'POST',
      customApiUrl: `${STRIPE_URL}`,
      body: payload,
    });
  },
  cancelSubscription: async ({ subscription_id }: ManageSubscriptionProps) => {
    return await fetcher(
      `${SUBSCRIPTIONS_ENDPOINT}/${subscription_id}/cancel`,
      {
        method: 'POST',
        customApiUrl: `${STRIPE_URL}`,
      },
    );
  },
  resumeSubscription: async ({ subscription_id }: ManageSubscriptionProps) => {
    return await fetcher(
      `${SUBSCRIPTIONS_ENDPOINT}/${subscription_id}/resume`,
      {
        method: 'POST',
        customApiUrl: `${STRIPE_URL}`,
        body: {
          subscription_id,
        },
      },
    );
  },
};

import { useQuery } from '@tanstack/vue-query';
import { subscriptionsService } from '~/utils/api/subscriptionsService';

export function useSubscriptions() {
  const { data, isLoading } = useQuery({
    queryKey: ['subscriptions'],
    queryFn: subscriptionsService.get,
  });

  const { data: user } = useUser();

  const currentSubscription = computed(() => {
    if (!user.value?.zmidAssoc?.is_active || !data.value) {
      return null;
    }

    const currentSubscription = data.value.find(
      (subscription) =>
        subscription.is_subscribed &&
        subscription.id === user.value.zmidAssoc?.subscribction_id,
    );

    return currentSubscription || null;
  });

  return {
    data,
    isLoading,
    currentSubscription,
  };
}

<script setup lang="ts">
defineProps<{
  closeModal: () => void;
}>();

const REFERRAL_SOURCE = [
  'Kurs ZMiD',
  'mailing',
  'Facebook',
  'LinkedIn',
  'Inne',
];

const CERTIFICATES = [
  'Makler Papierów Wartościowych',
  'Doradca Inwestycyjny',
  'Certyfikat Maklera Rynków Finansowych (MRF)',
  'Certyfikat ukończenia kursu ZMiD',
  'Certyfikat CIIA®',
  'Certyfikat CAI®',
  'Inne',
];

const { form, errors } = useMembershipJoinFormInjector();

const { firstName, lastName, data: user } = useUser();

function checkAllConsents() {
  form.ethics_consent = true;
  form.is_newsletter = true;
  form.zmid_consent = true;
}
</script>

<template>
  <form class="space-y-5">
    <p class="text-sm text-bluegray-900">
      Wypełnienie tego formularza to tylko zgłoszenie chęci wstąpienia do
      Związku Maklerów i Doradców - gdy otrzymamy Twoje zgłoszenie zostanie ono
      rozpatrzone przez Zarząd . Po przyjęciu Cię do grona Członków otrzymasz od
      nas informacje mailem.
    </p>

    <div class="flex gap-4">
      <DefaultInput v-model="firstName" disabled> Imię </DefaultInput>

      <DefaultInput v-model="lastName" disabled> Nazwisko </DefaultInput>
    </div>

    <div class="flex gap-4">
      <DefaultInput v-if="user" v-model="user.email" disabled>
        Adres e-mail
      </DefaultInput>

      <DefaultInput v-model="form.phone"> Numer telefonu </DefaultInput>
    </div>

    <div class="flex flex-col gap-3 text-sm text-bluegray-900">
      <header class="text-bluegray-600">
        Czy posiadasz licencję/certyfikat?
      </header>
      <div
        :key="index"
        v-for="(certificate, index) in CERTIFICATES"
        class="flex items-center"
      >
        <PrimeCheckbox
          :inputId="index"
          v-model="form.certificat_type"
          :value="certificate"
        />
        <label :for="index.toString()" class="ml-2"> {{ certificate }} </label>
      </div>

      <label class="flex items-center gap-2 text-global-textColor">
        <PrimeToggleSwitch
          :modelValue="
            !form.certificat_type || form.certificat_type?.length === 0
          "
          @change="() => (form.certificat_type = [])"
        />
        Nie posiadam żadnej licencji ani certyfikatu
      </label>
    </div>

    <div class="space-y-1">
      <header class="text-sm text-bluegray-600">
        Skąd dowiedziałeś się o Członkostwie w ZMiD?
      </header>
      <PrimeDropdown
        v-model="form.referral_source"
        :options="REFERRAL_SOURCE"
        placeholder="Wybierz z listy"
        :highlight-on-select="false"
        class="[&__span]:text-blugray-600 flex h-[38px] w-full flex-row items-center justify-between p-2.5 text-sm [&__span]:font-normal"
        clearable
      />
    </div>

    <div class="space-y-3 text-sm text-bluegray-900">
      <label class="flex items-center gap-2 text-global-textColor">
        <PrimeToggleSwitch
          :modelValue="
            form.ethics_consent && form.zmid_consent && form.is_newsletter
          "
          @change="checkAllConsents"
        />
        Zaznacz wszystkie zgody
      </label>

      <div class="flex items-start gap-2">
        <PrimeCheckbox
          v-model="form.zmid_consent"
          inputId="zmid_consent"
          binary
        />
        <label for="zmid_consent">
          *Oświadczam, że zapoznałem się z
          <NuxtLink
            class="underline"
            href="https://zmid.org.pl/wp-content/uploads/2021/08/20200615_Aktualny-Statut.pdf"
          >
            Statutem ZMID
          </NuxtLink>
          oraz że akceptuje jego treść.

          <small class="mt-1 block text-left text-xs text-red-600">
            {{ errors.zmid_consent }}
          </small>
        </label>
      </div>

      <div class="flex items-start gap-2">
        <PrimeCheckbox
          v-model="form.ethics_consent"
          inputId="ethics_consent"
          binary
        />
        <label for="ethics_consent">
          *Oświadczam, że zapoznałem się z
          <NuxtLink
            class="underline"
            href="https://zmid.org.pl/wp-content/uploads/2022/06/171023_Zasady_Etyki_Zawodowej_Maklerow-i-Doradcow.pdf"
          >
            Zasady Etyki Maklerów i Doradców
          </NuxtLink>
          oraz że akceptuje ich treść.

          <small class="mt-1 block text-left text-xs text-red-600">
            {{ errors.ethics_consent }}
          </small>
        </label>
      </div>

      <div class="flex items-start gap-2">
        <PrimeCheckbox
          v-model="form.is_newsletter"
          inputId="is_newsletter"
          binary
        />
        <label for="is_newsletter">
          Chcę otrzymywać Newsletter ZMID (możesz zrezygnować w każdej chwili).
        </label>
      </div>
    </div>

    <div class="flex justify-center gap-4">
      <PrimeButton rounded outlined @click="closeModal"> Anuluj </PrimeButton>

      <slot name="submit-button" />
    </div>
  </form>
</template>

import { fetcher } from '../fetcher';

const MEMBERSHIP_ENDPOINT = '/membership';

export const membershipService = {
  joinMembership: async (payload: MembershipProps) => {
    return await fetcher(`${MEMBERSHIP_ENDPOINT}/store-options`, {
      method: 'POST',
      body: {
        ...payload,
        certificat_type: payload.certificat_type?.join(','),
      },
    });
  },
  cancelMembership: async (payload: {
    subscription_id: string;
    cancel_reason?: string;
  }) => {
    return await fetcher(`${MEMBERSHIP_ENDPOINT}/cancel`, {
      method: 'POST',
      body: payload.cancel_reason
        ? payload
        : { subscription_id: payload.subscription_id },
    });
  },
};

import { fetcher } from '../fetcher';
import * as Sentry from '@sentry/vue';

const ADDRESSES_ENDPOINT = '/payments';

const { STRIPE_URL } = useRuntimeConfig().public;

if (!STRIPE_URL) {
  Sentry.captureException(
    new ResponseError('STRIPE_URL environment variable is not set', 111),
  );

  showError({
    message:
      'Coś poszło nie tak przy pobieraniu danych. Spróbuj ponownie później.',
  });
}

export const paymentsService = {
  getPaymentsHistory: async () => {
    return await fetcher(
      `${ADDRESSES_ENDPOINT}?paymentables[]=singlePayment&paymentables[]=subscription&with[]=paymentable`,
      {
        method: 'GET',
        customApiUrl: `${STRIPE_URL}`,
        schema: paymentsHistorySchema,
      },
    );
  },
};

export function useUserTrial() {
  const { data: user } = useUser();
  const { data: courses } = useUserCourses();

  const isUserTrial = computed(() => {
    return Boolean(user.value?.zmidAssoc === null && courses.value?.length);
  });

  return {
    isUserTrial,
  };
}

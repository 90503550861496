<script setup lang="ts">
definePageMeta({
  layout: 'authorized',
  middleware: ['auth'],
});

useHead({
  title: 'Członkostwo',
});
</script>

<template>
  <div class="space-y-8 py-6">
    <h2 class="text-xl font-semibold text-bluegray-900">Członkostwo w ZMiD</h2>

    <div class="grid grid-cols-5 gap-8">
      <div class="col-span-5 space-y-6 xl:col-span-3">
        <MembershipDetails />

        <PaymentSettings />

        <InvoiceInfoCard />
      </div>

      <div class="col-span-5 xl:col-span-2">
        <PaymentHistory />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { currentSubscription } = useSubscriptions();
const { data: products } = useProducts();
</script>

<template>
  <PrimeButton
    v-auto-animate
    v-if="!currentSubscription && products"
    rounded
    @click="$emit('createSubscription')"
  >
    Aktywuj członkostwo
  </PrimeButton>

  <PrimeButton
    v-auto-animate
    v-else-if="currentSubscription?.ends_at && products"
    rounded
    @click="$emit('resumeSubscription')"
  >
    Przedłuż członkostwo
  </PrimeButton>
</template>

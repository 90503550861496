import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { subscriptionsService } from '@/utils/api/subscriptionsService';
import { membershipService } from '@/utils/api/membershipService';
import type { CreateSubscriptionProps, MembershipForm } from '~/utils/types';

export function useCreateSubscription(form: MembershipForm) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isUserTrial } = useUserTrial();

  const { membershipSubscriptionPrice } = useProducts();

  const { mutate, isPending } = useMutation({
    mutationFn: async (payload: CreateSubscriptionProps) => {
      const data = await subscriptionsService.createSubscription(payload);

      if (!data.id) {
        throw Error('Wystąpił błąd podczas dodawania subskrypcji');
      }

      return membershipService.joinMembership({
        ...form,
        subscription_id: `${data.id}`,
      });
    },
    onSuccess: (data) => {
      toast.add({
        severity: 'success',
        detail: 'Subskrypcja została dodana',
        life: 3000,
      });

      [
        'subscriptions',
        'paymentsHistory',
        'userCourses',
        'schedules',
        'materials',
        'user',
      ].forEach((query) => {
        queryClient.invalidateQueries({
          queryKey: [query],
        });
      });
    },
    onError: () => {
      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas dodawania subskrypcji',
        life: 3000,
      });
    },
  });

  function handleJoinMembership({ onSuccess }: { onSuccess?: () => void }) {
    if (!membershipSubscriptionPrice.value) {
      throw new Error('No subscription price id found');
    }

    mutate(
      {
        price_id: membershipSubscriptionPrice.value.id,
        skip_trial: !isUserTrial.value,
      },
      { onSuccess },
    );
  }

  return {
    handleJoinMembership,
    isPending,
    isUserTrial,
  };
}

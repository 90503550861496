import { toTypedSchema } from '@vee-validate/zod';
import { membershipFormSchema } from '~/utils/schemas';
import type { MembershipForm } from '~/utils/types';

export function useMembershipJoinForm() {
  const membershipFormTypedSchema = computed(() =>
    toTypedSchema(membershipFormSchema),
  );

  const {
    form,
    errors,
  }: {
    form: MembershipForm;
    errors: any;
  } = useAppForm({
    fields: [
      'phone',
      'certificat_type',
      'is_newsletter',
      'ethics_consent',
      'zmid_consent',
      'referral_source',
    ],
    validationSchema: membershipFormTypedSchema,
  });
  form.ethics_consent = false;
  form.zmid_consent = false;

  return {
    form,
    errors,
  };
}

export function useMembershipJoinFormProvider() {
  const { form, errors } = useMembershipJoinForm();

  provide('membershipJoinForm', form);
  provide('membershipJoinFormErrors', errors);

  return {
    form,
    errors,
  };
}

export function useMembershipJoinFormInjector() {
  const form = inject<MembershipForm>('membershipJoinForm');
  const errors = inject<MembershipForm>('membershipJoinFormErrors');

  if (!form || !errors) {
    throw new Error(
      'useMembershipJoinFormInjector must be used after useMembershipJoinFormProvider',
    );
  }

  return {
    form,
    errors,
  };
}

import { fetcher } from '../fetcher';
import * as Sentry from '@sentry/vue';

const CARDS_ENDPOINT = '/cards';

const { STRIPE_URL } = useRuntimeConfig().public;

if (!STRIPE_URL) {
  Sentry.captureException(
    new ResponseError('STRIPE_URL environment variable is not set', 111),
  );

  showError({
    message:
      'Coś poszło nie tak przy pobieraniu danych. Spróbuj ponownie później.',
  });
}

export const cardsService = {
  getCards: async () => {
    return await fetcher(`${CARDS_ENDPOINT}`, {
      method: 'GET',
      customApiUrl: `${STRIPE_URL}`,
      schema: cardsSchema,
    });
  },
  createCard: async (payload: AddCardProps) => {
    return await fetcher(`${CARDS_ENDPOINT}`, {
      method: 'POST',
      customApiUrl: `${STRIPE_URL}`,
      body: payload,
    });
  },
  createPaymentIntent: async () => {
    return await fetcher(`${CARDS_ENDPOINT}/intent`, {
      method: 'GET',
      customApiUrl: `${STRIPE_URL}`,
    });
  },
  deleteCard: async (id: number) => {
    return await fetcher(`${CARDS_ENDPOINT}/${id}`, {
      method: 'DELETE',
      customApiUrl: `${STRIPE_URL}`,
    });
  },
  changeDefaultCard: async (payload: { id: number; is_default: boolean }) => {
    return await fetcher(`${CARDS_ENDPOINT}/${payload.id}`, {
      method: 'PATCH',
      customApiUrl: `${STRIPE_URL}`,
      body: {
        is_default: payload.is_default,
      },
    });
  },
};

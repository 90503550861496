import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import { cardsService } from '~/utils/api/cardsService';
import type { AddCardProps } from '~/utils/types';
import type { Cards } from '~/utils/types';

export function useCards() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ['cards'],
    queryFn: cardsService.getCards,
  });

  const { mutateAsync: createCard } = useMutation({
    mutationFn: (payload: AddCardProps) => cardsService.createCard(payload),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cards'] });

      toast.add({
        severity: 'success',
        detail: 'Karta została dodana',
        life: 3000,
      });
    },
    onError: () => {
      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas dodawania karty',
        life: 3000,
      });
    },
  });

  const { mutateAsync: createPaymentIntent } = useMutation({
    mutationFn: () => cardsService.createPaymentIntent(),
    onSuccess: () => {},
    onError: () => {
      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas dodawania karty',
        life: 3000,
      });
    },
  });

  const { mutate: deleteCard } = useMutation({
    mutationFn: (payload: number) => cardsService.deleteCard(payload),
    onMutate: (id) => {
      queryClient.setQueryData(['cards'], (data: Cards) => {
        return data.filter((card) => card.id !== id);
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['cards'] });
    },
    onError: () => {
      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas usuwania karty',
        life: 3000,
      });
    },
  });

  const { mutate: changeDefaultCard } = useMutation({
    mutationFn: (payload: number) =>
      cardsService.changeDefaultCard({
        id: payload,
        is_default: true,
      }),
    onMutate: (id) => {
      queryClient.setQueryData(['cards'], (data: Cards) => {
        return data
          .map((card) => {
            if (card.id === id) {
              return { ...card, is_default: true };
            }

            return { ...card, is_default: false };
          })
          .sort((a, b) => (a.is_default ? -1 : 1));
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['cards'] });
    },
    onError: () => {
      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas zmiany karty domyślnej',
        life: 3000,
      });
    },
  });

  const defaultCard = computed(() => {
    if (!data.value) {
      return null;
    }

    return data.value.find((card) => card.is_default) || null;
  });

  return {
    data,
    createPaymentIntent,
    createCard,
    deleteCard,
    changeDefaultCard,
    defaultCard,
  };
}

<script setup lang="ts">
const visible = ref(false);

const { defaultCard } = useCards();
const { data: address, isLoading: isAddressLoading } = useGetAddress();
const { data: user } = useUser();

const { form, errors } = useMembershipJoinFormProvider();
const {
  handleJoinMembership,
  isPending: isCreateSubscriptionPending,
  isUserTrial,
} = useCreateSubscription(form);
const { handleResumeMembership, isPending: isResumeSubscriptionPending } =
  useResumeSubscription(form);

const canUserSubscribe = computed(() => {
  return Boolean(defaultCard.value && address.value?.postal_code);
});
</script>

<template>
  <PrimeCard>
    <template #title>
      <h2 class="mb-6 text-lg font-semibold text-bluegray-900">Szczegóły</h2>
    </template>

    <template #content>
      <div v-if="isAddressLoading" class="text-center">
        <PrimeProgressSpinner class="h-8 w-8" />
      </div>

      <template v-else>
        <div
          class="grid grid-cols-1 gap-6 text-sm text-bluegray-900 lg:grid-cols-3"
        >
          <div class="space-y-1.5">
            <span class="text-bluegray-500">Plan</span>
            <div class="flex flex-wrap items-center gap-1.5">
              <MembershipBadge />
              Członkostwo ZMiD
            </div>
          </div>

          <div class="space-y-1.5">
            <span class="text-bluegray-500">Aktywne do</span>
            <div v-if="user?.zmidAssoc?.end_date">
              {{ user?.zmidAssoc?.end_date }}
            </div>
            <div v-else>-</div>
          </div>

          <template v-if="canUserSubscribe">
            <ManageMembershipButton @click="visible = true" />
          </template>

          <PrimeDialog
            v-model:visible="visible"
            modal
            header="Deklaracja członkostwa"
            :style="{ width: '485px', 'max-width': '90%' }"
            closeIcon="pi pi-times-circle"
            :closeButtonProps="{
              text: true,
              severity: 'secondary',
              style: 'box-shadow: none;',
            }"
          >
            <MembershipJoinForm :closeModal="() => (visible = false)">
              <template #submit-button>
                <ManageMembershipButton
                  :disabled="Object.keys(errors).length > 0"
                  :loading="
                    isResumeSubscriptionPending || isCreateSubscriptionPending
                  "
                  @createSubscription="
                    handleJoinMembership({
                      onSuccess: () => (visible = false),
                    })
                  "
                  @resumeSubscription="
                    handleResumeMembership({
                      onSuccess: () => (visible = false),
                    })
                  "
                />
              </template>
            </MembershipJoinForm>
          </PrimeDialog>
        </div>

        <PrimeMessage
          v-if="isUserTrial"
          class="!mb-0 mt-6 [&__div]:items-start [&__div]:gap-1"
          severity="info"
          icon="pi pi-info-circle !text-2xl"
        >
          <div class="mb-2 text-sm font-bold">Informacja dla Kursantów</div>
          <div class="text-sm font-normal">
            Jako Kursant ZMiD możesz aktywować pierwszy rok członkostwa w ZMID
            za darmo. Po podpięciu karty pierwszą składkę pobierzemy za 365 dni.
            Możesz anulować w dowolnej chwili.
          </div>
        </PrimeMessage>

        <PrimeMessage
          v-if="!canUserSubscribe"
          class="!mb-0 mt-6 [&__div]:items-start [&__div]:gap-1"
          severity="warn"
          icon="pi pi-info-circle !text-2xl"
        >
          <div class="mb-2 text-sm font-bold">
            Brak wymaganych danych do
            {{ !!user?.zmidAssoc?.is_active ? 'przedłużenia' : 'aktywowania' }}
            Członkostwa
          </div>

          <div
            v-if="!defaultCard && !address?.postal_code"
            class="text-sm font-normal"
          >
            Aby
            {{ !!user?.zmidAssoc?.is_active ? 'przedłużyć' : 'aktywować' }}
            Członkostwo, musisz najpierw dodać domyślną metodę płatności w
            sekcji poniżej oraz uzupełnić swój adres rozliczeniowy.
          </div>
          <div v-else-if="!address?.postal_code" class="text-sm font-normal">
            Aby
            {{ !!user?.zmidAssoc?.is_active ? 'przedłużyć' : 'aktywować' }}
            Członkostwo, uzupełnij swój adres rozliczeniowy poniżej.
          </div>
          <div v-else-if="!defaultCard" class="text-sm font-normal">
            Aby
            {{ !!user?.zmidAssoc?.is_active ? 'przedłużyć' : 'aktywować' }}
            Członkostwo, musisz najpierw dodać domyślną metodę płatności w
            sekcji poniżej.
          </div>
        </PrimeMessage>
      </template>
    </template>
  </PrimeCard>
</template>

<script setup lang="ts">
const { data: address, isLoading: isAddressLoading } = useGetAddress();
const { data: user } = useUser();

const isEditing = ref(false);
</script>

<template>
  <PrimeCard>
    <template #title>
      <h2
        class="mb-6 flex items-center gap-9 text-lg font-semibold text-bluegray-900"
      >
        Adres rozliczeniowy

        <PrimeButton class="ml-auto" text @click="isEditing = true">
          <i class="pi pi-user-edit" />
          Edytuj
        </PrimeButton>
      </h2>
    </template>

    <template #content>
      <div v-auto-animate>
        <InvoiceForm
          v-if="isEditing"
          :isCompany="false"
          :onSuccessfulSubmit="() => (isEditing = false)"
          type="invoice"
        />

        <template v-else>
          <div v-if="isAddressLoading" class="text-center">
            <PrimeProgressSpinner class="h-8 w-8" />
          </div>

          <div
            v-else-if="!address?.postal_code"
            class="text-sm text-bluegray-900"
          >
            Nie uzupełniono adresu rozliczeniowego.
          </div>

          <div v-else class="grid grid-cols-2 gap-6 text-sm text-bluegray-900">
            <div class="space-y-1.5">
              <span class="text-bluegray-500">Imię i nazwisko</span>
              <div class="flex items-center gap-1.5">
                {{ address?.first_name }} {{ address?.last_name }}
              </div>
            </div>

            <div class="space-y-1.5">
              <span class="text-bluegray-500">E-mail</span>
              <div>{{ user?.email }}</div>
            </div>

            <div class="space-y-1.5">
              <span class="text-bluegray-500">Adres</span>
              <div>
                {{ address?.line1 }}
                <br />
                {{ address?.postal_code }} {{ address?.city }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </PrimeCard>
</template>
